<script setup>
// import Tempus from '@studio-freight/tempus';

const props = defineProps({
    color: {
        type: String,
        default: 'white',
    },
    isSticky: {
        type: Boolean,
        default: true,
    },
    blocks: {
        type: Array,
        default: () => [],
    },
});

const root = ref(null);
// const { observe } = useResizeObserver();
// const { height } = observe(root);
const cleanBlocks = computed(() => props?.blocks.filter((b) => b?.typeHandle));
// const { innerHeight, scrollTop } = useClientState();

onMounted(() => {
    // Only add the sticky class on mounted otherwhise there's a jump due
});

</script>
<template>
    <section
        ref="root"
        class=""
    >
        <component
            :is="'Flexible'+block.typeHandle"
            v-for="(block, index) in cleanBlocks"
            :key="'block'+index"
            :data="block"
        />
    </section>
</template>

<style scoped lang="postcss">
.panel.notsticky + .panel.sticky {
    @apply !rounded-t-none;
}
</style>
